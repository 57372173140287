<template>
  <el-dialog
      title="修改支出项目"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="项目名称" prop="projectsName">
            <el-input v-model="form.projectsName" placeholder="请填写支出项目名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="所属类目" prop="projectsName">
            <el-select v-model="form.categoryId" placeholder="请选择支出项目类目" style="width: 100%">
              <el-option
                  v-for="item in expenditureArray"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-item-edit",
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    project: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryAppProjectList();
  },
  data() {
    return {
      form: {
        projectsName: this.project.projectsName,
        categoryId: this.project.categoryId,
        projectsType: this.$projectsType.category,
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        projectsName: [{required: true, message: '请填写预约项目名称', trigger: 'blur'}],
        preAfterSale: [{required: true, message: '请选择预约项目类型', trigger: 'change'}],
      },
      states: this.state,
      expenditureArray: []
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/projects/updateProject",
            params: {
              id: this.project.id,
              ...this.form
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "修改成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    },

    queryAppProjectList() {
      this.$axios({
        method: "GET",
        url: "/spendCategory/spendCategoryList",
        page: this.page,
        limit: this.limit,
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.expenditureArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
  },
}
</script>

<style scoped>

</style>