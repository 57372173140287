<template>
  <div>
    <el-row style="padding-left: 10px">
      <el-button type="primary" @click="addOpen">添加支出项目参数</el-button>
    </el-row>
    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="appProjectList"
          border
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          max-height="700"
          style="width: 99%;">
        <el-table-column label="项目名称" align="center" #default="scope">
          {{scope.row.projectsName}}
<!--          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>-->
        </el-table-column>
        <el-table-column label="项目等级" align="center" #default="scope">
          {{scope.row.type}}
        </el-table-column>
        <el-table-column label="支出项目类目" align="center" #default="scope">
          {{scope.row.categoryName}}
        </el-table-column>
        <el-table-column label="是否激活" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button type="primary" v-if="scope.row.isDefault !== 1" size="mini" @click="editOpen(scope.row)">修改</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500, 1000]"
          :page-size="50"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <app-projects-add v-if="addState" :state="addState" @close="addClose"></app-projects-add>
    <app-projects-edit v-if="editState" :state="editState" :project="project" @close="editClose"></app-projects-edit>

    <br>
    <br>
    <el-row style="padding-left: 10px">
      <el-button type="primary"
                 @click="openAll = !openAll">{{openAll ? "关闭所有展开" : "全部展开"}}
      </el-button>
    </el-row>

    <!--默认显示展开的 点击按钮可控制-->
    <div v-show="openAll">
      <el-row style="margin-top: 20px;padding-left: 10px">
        <el-table
            lay-filter="accountTableFilter"
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            ref="topicTable"
            :row-key="getRowKeys"
            border
            :default-expand-all="true"
            :cell-style="cellStyle"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column
                label="项目名称"
                width="400"
                prop="projectsName"
                align="left">
            </el-table-column>
            <el-table-column
                label="项目等级"
                prop="type"
                width="120"
                align="center">
            </el-table-column>
  <!--          <el-table-column-->
  <!--              label="ID"-->
  <!--              prop="id"-->
  <!--              width="100"-->
  <!--              align="center">-->
  <!--          </el-table-column>-->
  <!--        </el-table-column>-->
        </el-table>
      </el-row>
    </div>

    <!--默认显隐藏全部关闭的 点击按钮可控制-->
    <div v-show="!openAll">
      <el-row style="margin-top: 20px;padding-left: 10px">
        <el-table
            lay-filter="accountTableFilter"
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            ref="topicTable"
            :row-key="getRowKeys"
            border
            :default-expand-all="false"
            :cell-style="cellStyle"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
              label="项目名称"
              width="400"
              prop="projectsName"
              align="left">
          </el-table-column>
          <el-table-column
              label="项目等级"
              prop="type"
              width="120"
              align="center">
          </el-table-column>
  <!--        <el-table-column-->
  <!--            label="ID"-->
  <!--            prop="id"-->
  <!--            width="100"-->
  <!--            align="center">-->
  <!--        </el-table-column>-->
        </el-table>
      </el-row>
    </div>
  </div>
</template>

<script>
import appProjectsAdd from "@/pages/parameter/spend/spend-item-add.vue"
import appProjectsEdit from "@/pages/parameter/spend/spend-item-edit.vue"
export default {
  //支出项目列表
  name: "spend-item-list",
  components: {
    appProjectsAdd,
    appProjectsEdit
  },
  created() {
    this.queryExpenditureList()
    this.queryProjectsTree()
  },
  data() {
    return {
      appProjectList:[],
      total: 0,
      page: 1,
      limit: 50,
      addState: false,
      editState: false,
      project: {},
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489',

      openAll: true,
      tableData: [],
    }
  },
  methods: {
    queryExpenditureList() {
      this.$axios({
        method: "GET",
        url: "/projects/projectsVoList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          projectsType: this.$projectsType.category,
          page: this.page,
          limit: this.limit
        }
      }).then(response => {
        this.appProjectList = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryExpenditureList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryExpenditureList()
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/projects/updateProject",
        params: {
          id: val.id,
          isValid: val.isValid,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response =>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryExpenditureList()
      })
    },
    addOpen() {
      this.addState = true
    },
    addClose() {
      this.addState = false
      this.queryExpenditureList()
    },
    editOpen(val) {
      this.project = val
      this.editState = true
    },
    editClose() {
      this.editState = false
      this.queryExpenditureList()
    },
    isDefaultChange(val) {
      this.$axios({
        method: 'post',
        url: "/projects/updateProject",
        params: {
          id: val.id,
          isDefault: val.isDefault,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryExpenditureList()
      })
    },
    getRowKeys(row) {
      return row.id;
    },
    //改变子表格字体颜色
    childrenRol() {
      return "color: #4c8daa;";
    },
    //行样式
    cellStyle(row) {
      return (row.row.type == "二级项目") ? "color: #FFA54F;" : "color: #4c8daa";
    },
    queryProjectsTree() {
      this.$axios({
        method: "GET",
        url: "/projects/projectsTree",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          projectsType: this.$projectsType.category,
          page: this.page,
          limit: this.limit
        }
      }).then(response => {
        this.tableData = response.data.data
      })
    },

  },
}
</script>

<style scoped>

</style>